const BASE_URL = "https://urchin-app-nwm2y.ondigitalocean.app/api";
// const BASE_URL = "http://localhost:8000/api";

export default BASE_URL;

//https://stingray-app-myegm.ondigitalocean.app/api
//http://64.227.128.113/api
//https://sstonebats.com/api/
//https://whale-app-rqcs6.ondigitalocean.app/api

